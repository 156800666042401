:root {
  --bs-font-sans-serif: "Nissan Light", "Nissan Regular", Verdana, Arial, sans-serif;
  --bs-body-font-size: 1.15rem;
}

.c_019 {
  h2 {
    &:before {
      display: none;
    }

  }

  strong {
    font-family: "Nissan Bold", "Nissan Regular", Verdana, Arial, sans-serif;
  }
}

h3 {
  position: relative;
  font-size: 1.25rem;
  font-family: "Nissan Bold", "Nissan Regular", Verdana, Arial, sans-serif;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-top: 4px solid #c3002f;
    width: 50px;
  }
}

.red {
  color: $red;
}

@media print, screen and (min-width: 60em) {
  .c_019 h2 {
    font-size: 30px;
  }
}
