.background-gray {
  background-color: $gray-light;
}

.background-gray-triangle-bottom {
  background-image: url('../../images/busqueda/background-search.svg');
  padding-bottom: 2rem;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  position: relative;
  display: block;
  @include media-breakpoint-up(sm) {
    padding-bottom: 4rem;
  }
  @include media-breakpoint-up(md) {
    padding-bottom: 5rem;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 8rem;
  }

  &:before {
    content: ' ';
    position: absolute;
    background-color: $gray-light;
    left: 0;
    right: 0;
    top: 0;
    bottom: 2rem;
    z-index: -1;
    @include media-breakpoint-up(sm) {
      bottom: 4rem;
    }
    @include media-breakpoint-up(md) {
      bottom: 5rem;
    }
    @include media-breakpoint-up(lg) {
      bottom: 8rem;
    }
  }
}

.background-color-textos-campos {
  background-color: $color-textos-campos;
}
