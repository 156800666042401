@import "~bootstrap";

@import "./core/variables";
@import "./core/text";
@import "./core/backgrounds";
@import "./core/borders";
@import "./core/buttons";
@import "./core/radiobutton";
@import "./core/checkbox";
@import "./core/form";
