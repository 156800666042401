.form-note {
  font-size: 1rem;
}

.error {
  & + .form-note {
    display: none;
  }
}

.error-message {
  &:before {
    display: none;
  }
}

.text-input-hidden {
  opacity: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 0 !important;
}

label.checkcontainer {
  &:after {
    display: none;
  }

  &:has(input:checked) {
    &:after {
      display: inline-block;
    }
  }
}
