.c_019 {
  button {
    padding: 15px 45px 15px 20px;
    @include media-breakpoint-up(lg) {
      padding: 16px 45px 15px 20px;
    }
  }

  button[type=button] {
    padding-right: 15px;
    padding-left: 15px;
    @include media-breakpoint-up(lg) {
      padding-right: 20px;
      padding-left: 20px;
    }

    &:after {
      display: none;
    }
  }

  button[type=submit] {
  }

  .button-back {
    background-color: $gray-light;
    color: #000;
    position: relative;
    padding: 16px 20px 15px 45px;
    margin-top: 0;
    width: 100%;
    border: 1px solid $gray-light;

    &:after {
      content: '';
      color: #000;
      font-size: .7857142857rem;
      right: unset;
      left: 20px;
      top: 50%;
      margin-top: -0.5em;
      position: absolute;
    }

    &:hover {
      background-color: $gray-light;
      color: $red;
      border: 1px solid $red;

      &:after {
        transform: translateX(-5px);
      }
    }
  }
}

.status {
  background-color: $color-textos-campos;
  color: #fff;
  font-family: "Nissan Bold", "Nissan Regular", Verdana, Arial, sans-serif;
  padding: 16px 20px 15px 20px;
}
