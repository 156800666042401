label.checkcontainer, {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
  margin-left: 0;
  margin-right: 30px;
  padding-left: 30px;
  font-family: "Nissan Light", "Nissan Regular", Verdana, Arial, sans-serif;

  a {
    &:hover, &:focus, &:active, &:visited {
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0px;
    margin-right: 8px;
    height: 20px;
    width: 20px;
    border: 1px solid $gray;
    border-radius: 50%;
    transition: all .3s;

    &:after {
      content: "";
      position: absolute;
      display: none;

      top: 2px;
      left: 2px;

      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: $red;
    }
  }

  &:hover input ~ .checkmark {
    background-color: #fff;
  }

  input:checked ~ .checkmark {
    background-color: #fff;
    box-shadow: 0 0 0 1px #000;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}
